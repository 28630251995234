import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { Grid,Box,AspectImage,AspectRatio,Card,Button,Image,Text, Paragraph } from 'theme-ui'
import theme  from "../gatsby-plugin-theme-ui";

function Books({data}){
    return(
        <Layout>
          <Grid className="books-display-page" gap={2} columns={[2, null, 4]}>
          {data.allContentfulBooks.edges.map(( {node} )=>{
            return(
            <Box>
            <Link to={"/books/"+node.slug}>
            <Card className="books-display">
            <img src={node.books.fixed.src} />
            <Paragraph>
            <Text>
              {node.title}
              </Text>
            </Paragraph>
            </Card>
            </Link>
            </Box>
            )
          })
        }
          </Grid>
        </Layout>
    )
}

export default Books
export const query = graphql `
  query{
    allContentfulBooks {
        edges {
          node {
            title
            slug
            contentful_id
            books {
              id
              fixed(width: 150,height:250) {
                src
              }
            }
          }
        }
      }
}
`